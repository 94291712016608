import React from "react";

export const TermsAndConditions = ({ changeLanguage }) => {
  return (
    <>
      <div className="sticky title"></div>
      <div className="title">
        <h2>
          {changeLanguage ? "TERMS AND CONDITIONS" : "TÉRMINOS Y CONDICIONES"}
        </h2>
      </div>
      <div className="container my-5">
        <h3>{changeLanguage ? "1.Acceptance" : "1.Aceptación"}</h3>
        <p className="textHistory">
          {changeLanguage
            ? 'This document (hereinafter, the “Contract”) establishes the terms and conditions of LC Pharma Bolivia SRL., With address at C. / Luis Crespo Nº 2019 esq. Aspiazu, which will apply to access and use by the User of this website ( www.lcpharmabolivia.com) . Please read this Agreement carefully. By accessing, consulting or using the Website, Users ("You", "you", "User", or "user") agree to comply with the terms and conditions established in this Agreement. If you do not agree to be bound by these terms and conditions, you will not be able to access, or use, the Website. LC Pharma Boliva SRL. reserves the right to update this Agreement whenever they deem it appropriate. Consequently, we recommend that the User periodically review the modifications made to the Contract.'
            : "En el presente documento (en adelante, el “Contrato”) se establecen los términos y condiciones de LC Pharma Bolivia SRL., con domicilio en C./ Luis Crespo Nº 2019 esq. Aspiazu, que serán de aplicación al acceso y uso por parte del Usuario de esta página web (www.lcpharmabolivia.com). Les rogamos lean atentamente el presente Contrato. Al acceder, consultar o utilizar el Sitio Web, los Usuarios (“Vd.”, “usted”, “Usuario”, o “usuario”) aceptan cumplir los términos y condiciones establecidos en este Contrato. En caso de que usted no acepte quedar vinculado por los presentes términos y condiciones, no podrá acceder a, ni utilizar, el Sitio Web. LC Pharma Boliva SRL. se reserva el derecho de actualizar el presente Contrato siempre que lo consideren oportuno. En consecuencia, recomendamos al Usuario revisar periódicamente las modificaciones efectuadas al Contrato."}
        </p>
        <h3>
          {changeLanguage
            ? "2.User requirements"
            : "2.Requisitos relativos al usuario"}
        </h3>
        <p className="textHistory">
          {changeLanguage
            ? "The Website and the services related to it are offered to Users who have the legal capacity to grant legally binding contracts according to the applicable legislation."
            : "El Sitio Web y los servicios relacionados con el mismo se ofrecen a los Usuarios que tengan capacidad legal para otorgar contratos legalmente vinculantes según la legislación aplicable."}
        </p>
        <h3>{changeLanguage ? "3.Licence" : "3.Licencia"}</h3>
        <p className="textHistory">
          {changeLanguage
            ? "In this act, LC Pharma Bolivia grants the User a limited, non-exclusive, non-transferable, non-transferable and revocable license; to consult and download, temporarily, a copy of the content offered on the Website, solely for the User's personal use or within his company, and never for commercial purposes. All material displayed or offered on the Website, among other examples, graphic material, documents, texts, images, sound, video, audio, illustrations, software and HTML code (collectively, the 'Content') , is the exclusive property of LC Pharma Bolivia or of the companies that provide said material. The Content is protected by Bolivian copyright laws, as well as by other laws, regulations and standards applicable to intellectual property rights. Unless expressly provided otherwise in this contract, and / or unless by legal imperative this is expressly permitted by laws derogating those currently in force, the User may not (i) use, copy, modify, display, delete, distribute, download , store, reproduce, transmit, publish, sell, resell, adapt, reverse the creation process or create derivative products from, the Content. Nor may you (ii) use the Content on other Web pages or in any means of communication, such as in a network environment, without the prior written authorization of LC Pharma in this regard. All trademarks, service marks and logos (hereinafter, the 'Trademarks') displayed on the Website are the exclusive property of LC Pharma Bolivia and their respective owners. The User may not use the Trademarks in any way without the prior written authorization of LC Pharma Bolivia and the respective owners."
            : "En este acto, LC Pharma Bolivia otorga al Usuario una licencia limitada, no exclusiva, intransferible, no susceptible de cesión y revocable; para consultar y descargar, de forma temporal, una copia del contenido ofrecido en el Sitio Web, únicamente para uso personal del Usuario o dentro de su empresa, y nunca con fines comerciales. Todo el material mostrado u ofrecido en el Sitio Web, entre otros ejemplos, el material gráfico, los documentos, textos, imágenes, sonido, video, audio, las ilustraciones, el software y el código HTML (en conjunto, el “Contenido”), es de exclusiva propiedad de LC Pharma Bolivia o de las empresas que facilitan dicho material. El Contenido está protegido por las leyes de copyright Bolivianas, así como por las demás leyes, reglamentos y normas aplicables a los derechos de propiedad intelectual. Salvo disposición expresa en contrario en el presente contrato, y/o salvo que por imperativo legal ello esté expresamente permitido por leyes derogatorias de las actualmente vigentes, el Usuario no podrá (i) utilizar, copiar, modificar, mostrar, eliminar, distribuir, descargar, almacenar, reproducir, transmitir, publicar, vender, revender, adaptar, invertir el proceso de creación o crear productos derivados a partir de, el Contenido. Tampoco podrá (ii) utilizar el Contenido en otras páginas Web o en cualquier medio de comunicación como, por ejemplo, en un entorno de red, sin la previa autorización por escrito en este sentido de LC Pharma. Todas las marcas comerciales, las marcas de servicio y los logos (en adelante, las “Marcas”) mostrados en el Sitio Web son propiedad exclusiva de LC Pharma Bolivia y de sus respectivos propietarios. El Usuario no podrá utilizar las Marcas en modo alguno sin la previa autorización por escrito para ello de LC Pharma Bolivia y los respectivos propietarios."}
        </p>
        <h3>
          {changeLanguage
            ? "4.Information requested by the user"
            : "4.Información solicitada por el usuario"}
        </h3>
        <p className="textHistory">
          {changeLanguage
            ? "LC Pharma Bolivia reserves the right, at its own discretion, to delete, withdraw, refuse to reflect or block any Information sent by the User that LC Pharma Bolivia considers unacceptable. In the event that LC Pharma Bolivia receives any notification about the unacceptability of certain information provided by users, LC Pharma Bolivia may, with total discretion, investigate said information. The User assumes and accepts that LC Pharma Bolivia may keep a copy of the User's Information, and reveal said information to third parties if it considers it necessary to: (i) protect the integrity of the Website; (ii) protect the rights of LC Pharma Bolivia; (iii) comply with a court order; (iv) comply with any legal procedure; (v) enforce the rights and actions that assist LC Pharma Bolivia under this Agreement; Y (vi) satisfy any request related to the infringement of the rights of third parties."
            : "LC Pharma Bolivia se reserva el derecho de, a su libre JUICIO, eliminar, retirar, negarse a reflejar o bloquear toda Información enviada por el Usuario que LC Pharma Bolivia considere como inaceptable. En caso de que LC Pharma Bolivia reciba alguna notificación sobre la inaceptabilidad de determinada información facilitada por los usuarios, LC Pharma Bolivia podrá, con total discrecionalidad, investigar dicha información. El Usuario asume y acepta que LC Pharma Bolivia podrá conservar copia de la Información del Usuario, y revelar dicha información a terceros si lo considera necesario para: (i) proteger la integridad del Sitio Web; (ii) proteger los derechos de LC Pharma Bolivia; (iii) cumplir una orden judicial; (iv) cumplir cualquier trámite legal; (v) hacer valer los derechos y acciones que asisten a LC Pharma Bolivia a tenor de este Contrato; y (vi) satisfacer cualquier petición relativa a la infracción de derechos de terceros."}
        </p>
        <h3>{changeLanguage ? "5.Links from third party websites" : "5.Vínculos de paginas web de terceros"}</h3>
        <p className="textHistory">
          {changeLanguage
            ? "The Website may have links, such as hyperlinks or links, that indicate access to third-party Web pages (the 'Related Sites'). Although LC Pharma Bolivia reviews the material of third parties and only allows the inclusion of Related Sites that, to the best of its knowledge and belief, do not violate applicable law, LC Pharma Bolivia cannot control or monitor the Related Sites and, in Consequently, it will not assume any responsibility regarding the accuracy, security or reliability of the material, information or content included in said Related Sites. The inclusion of the Related Sites on the Website does not imply that there is any relationship or association between LC Pharma Bolivia and the owner of the Related Sites, nor does it imply the approval or promotion by HR of said Related Sites. LC Pharma Bolivia includes the Related Sites for the User's convenience only. This is solely responsible for access to the Related Sites. The User must use their own good judgment, caution and common sense when using the Related Sites and, for this, we recommend reviewing the conditions of use and the privacy terms that govern said Related Sites. LC Pharma Bolivia reserves the right to remove from its Website all Related Sites of which it has real knowledge that they are infringing the rights of third parties and / or whose content violates the applicable legislation, or if so required by a court order or a administrative ordinance."
            : "El Sitio Web puede tener vínculos, como hipervínculos o enlaces, que indican el acceso a páginas Web de terceros (los “Sitios Relacionados”). Aunque LC Pharma Bolivia revisa el material de terceros y solamente permite la inclusión de los Sitios Relacionados que, a su leal saber y entender, no infringen la legislación aplicable, LC Pharma Bolivia no puede controlar ni realizar un seguimiento de los Sitios Relacionados y, en consecuencia, no asumirá responsabilidad alguna en cuanto a la exactitud, seguridad o fiabilidad del material, información o contenido incluido en dichos Sitios Relacionados. La inclusión de los Sitios Relacionados en el Sitio Web no implica que haya relación o asociación alguna entre LC Pharma Bolivia y el propietario de los Sitios Relacionados, ni la aprobación o promoción por parte de RH de dichos Sitios Relacionados. LC Pharma Bolivia incluye los Sitios Relacionados solamente para comodidad del Usuario. Este es el único responsable del acceso a los Sitios Relacionados. El Usuario deberá utilizar su propio buen criterio, precaución y sentido común a la hora desusar los Sitios Relacionados y, para ello, le recomendamos revisar el condicionado de uso y los términos que sobre privacidad rigen en dichos Sitios Relacionados. LC Pharma Bolivia se reserva el derecho de eliminar de su Sitio Web todos los Sitios Relacionados de los que tenga conocimiento real que están infringiendo derechos de terceros y/o cuyo contenido vulnere la legislación aplicable, o si así se lo exige una orden judicial o una ordenanza administrativa."}
        </p>
        <h3>{changeLanguage ? "6.Privacy" : "6.Privacidad"}</h3>
        <p className="textHistory">
          {changeLanguage
            ? "The Website and the services related to it are offered to Users who have the legal capacity to grant legally binding contracts according to the applicable legislation."
            : "El Sitio Web y los servicios relacionados con el mismo se ofrecen a los Usuarios que tengan capacidad legal para otorgar contratos legalmente vinculantes según la legislación aplicable."}
        </p>
        <h3>7.Cookies</h3>
        <p className="textHistory">
          {changeLanguage
            ? "LC Pharma Bolivia may use cookies to track the use by the User, as well as the registration request, the submission of information and the request for information by the User. In the 'Help' section of the toolbar of most search engines, the User is told how to prevent his search engine from accepting new cookies, how to make his search engine notify him of the receipt of new cookies, or how to disable all cookies . However, if the User disables cookies, some of the applications on our Website may also be disabled, and the use of the Web pages may be limited.  "
            : "LC Pharma Bolivia podrá utilizar cookies para rastrear el uso por parte del Usuario, así como la solicitud de registro, la remisión de información y la petición de información por parte de este. En el apartado “Ayuda” de la barra de herramientas de la mayoría de los buscadores se indica al Usuario cómo impedir que su buscador acepte nuevas cookies, cómo hacer que su buscador le avise de la recepción de nuevas cookies, o cómo inhabilitar todas las cookies. Sin embargo, si el Usuario inhabilita las cookies, algunas de las aplicaciones de nuestro Sitio Web pueden quedar también inhabilitadas, y el uso de las páginas Web puede verse limitado."}
        </p>
      </div>
    </>
  );
};
